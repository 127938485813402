@use './variables' as v;
:root{
    --branch-color: #EF3E30;
}
*{
    box-sizing: border-box;
    background: transparent;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
}
html {
    color-scheme: dark;
    text-rendering: optimizeLegibility;
    --webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-size: 16px;
    overflow-x: hidden;
}
body{
    font-family: v.$font-sans-serif;
    font-size: 1rem;
    background: v.$body-background;
    overflow-x: hidden;
}

@mixin margin($direction, $size) {
    @if $direction == 'all' {
        margin: #{$size / 10}rem !important;
    } @else {
        margin-#{$direction}: #{$size / 10}rem !important;
    }
}

.margin {
    @for $i from 1 through 100 {
        &-#{$i} {
            @include margin('all', $i);
        }
        &-top-#{$i} {
            @include margin('top', $i);
        }
    
        &-bottom-#{$i} {
            @include margin('bottom', $i);
        }
    
        &-left-#{$i} {
            @include margin('left', $i);
        }
    
        &-right-#{$i} {
            @include margin('right', $i);
        }
    }
}

@mixin align($direction) {
    align-items: #{$direction};
}

@mixin justify($direction) {
    justify-content: #{$direction};
}

@mixin flow($flow) {
    flex-flow: #{$flow};
}

.flex{
    display: flex;
    &.align{
        @each $direction in center, flex-start, flex-end, baseline, stretch {
            &-#{$direction} {
                @include align($direction)
            }
        }
    }
    &.justify{
        @each $direction in center, flex-start, flex-end, baseline, stretch {
            &-#{$direction} {
                @include justify($direction)
            }
        }
    }
    &.flow{
        @each $flow in row, row-reverse, column, column-reverse {
            &-#{$flow} {
                @include flow($flow)
            }
        }
    }
}

@mixin gap($gap) {
    gap: #{$gap / 10}rem;
}
.gap{
    @for $i from 1 through 100 {
        &-#{$i} {
            @include gap($i)
        }
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    > *{
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
}

.MuiCircularProgress-root{
    width: 1em !important;
    height: 1em !important;
    svg, .svg{
        font-size: 1em !important;
    }
}

button,
.button,
button.button,
a.button{
    text-transform: initial;
    gap: .5rem;
    border-radius: 10px;
    font-family: v.$font-branch;
    padding: .5rem 1rem;
    min-width: max-content;
    span{
        color: currentColor;
    }
    .icon{
        display: flex;
        align-items: center;
        svg{
            font-size: 1.25em;
            width: 1.25em;
            height: 1.25em;
        }
    }
    &:disabled{
        opacity: .5;
    }
    &.loading,
    &.with-loading{
        .title,
        .icon{
            visibility: hidden;
        }
        .progress{
            position: absolute;
            display: flex;
            > span{
                width: 1.25em !important;
                height: 1.25em !important;
                svg, .svg {
                    font-size: 1.25em !important;
                }
            }
        }
    }
    &.shadow{
        --shadow-color: transparent;
        background: #FFF !important;
        transition: .2s ease all;
        outline: 2px solid;
        border-radius: 100px;
        color: rgba($color: var(--shadow-color), $alpha: .5);
        box-shadow: 0 1rem 45px 0 rgba($color: var(--shadow-color), $alpha: 0), 
                    0 1rem 41px 0 rgba($color: var(--shadow-color), $alpha: .01), 
                    0 1rem 41px 0 rgba($color: var(--shadow-color), $alpha: .01), 
                    0 1rem 26px 0 rgba($color: var(--shadow-color), $alpha: .04),
                    0 1rem 14px 0 rgba($color: var(--shadow-color), $alpha: .05),
        ;
        &:hover{
            outline-color: transparent;
            transform: translateY(-2px);
            box-shadow: 0 1rem 45px 0 rgba($color: var(--shadow-color), $alpha: 0), 
                        0 1rem 41px 0 rgba($color: var(--shadow-color), $alpha: .08), 
                        0 1rem 41px 0 rgba($color: var(--shadow-color), $alpha: .08), 
                        0 1rem 26px 0 rgba($color: var(--shadow-color), $alpha: .11),
                        0 1rem 14px 0 rgba($color: var(--shadow-color), $alpha: .12),
            ;
        }
        .title{
            color: #333;
        }
        &.laravel{
            --shadow-color: 239, 62, 48;
            
        }
        &.asp-net{
            --shadow-color: 120, 90, 221; 
        }
    }
    &.work-category{
        --color: 255 255 255;
        flex-flow: row;
        flex: 1;
        min-width: 300px;
        padding: .75rem;
        border-radius: 1.25rem;
        transition: .2s ease all;
        background: #FFF;
        border: 1px solid rgba($color: var(--color), $alpha: .4);
        gap: .75rem;
        &:hover{
            transform: scale(1.015);
            border: 1px solid rgba($color: var(--color), $alpha: .7);
        }
        &.laravel{
            --color: 239, 62, 48;
        }
        &.asp-net{
            --color: 120, 90, 221; 
        }
        &.git-hub{
            --color: 0, 0, 0;
        }
        &.special{
            flex: 1;
            min-width: 100%;
            width: 100%;
        }
        .cover{
            outline: 2px solid transparent;
            color: rgb(var(--color));
            width: 1em;
            aspect-ratio: 1 / 1;
            font-size: 5vmax;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .5rem;
            transition: .2s ease all;
            svg{
                font-size: 1em;
            }
            img{
                width: 100%;
            }
        }
        .body{
            flex: 1;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            justify-content: space-between;
            height: 100%;
            .text{
                text-align: left;
                display: flex;
                flex-flow: column;
            }
        }
        span{
            color: v.$text-subdued;
            line-height: 1;
        }
    }
    &.variant-{
        &outlined{
            background: transparent;
            color: v.$text-base;
            border: 1px solid v.$decorative-subdued;
            &.color-{
                &primary{
                    &.active,
                    &:hover{
                        background: var(--branch-color);
                        color: v.$text-base-inverted;
                        border-color: transparent;
                    }
                }
            }
        }
        &contained{
            color: v.$text-base-inverted;
            background: var(--branch-color);
            border: 1px solid transparent;
            &.color-{
                &primary{
                    &:hover{
                        background: transparent;
                        color: v.$text-highlight;
                        border-color: v.$decorative-subdued;
                    }
                }
            }
        }
    }
    &.icon-button{
        aspect-ratio: 1 / 1;
        min-width: 0;
        padding: .75rem;
        border-radius: 10px;
        &:disabled,
        .disabled{
            opacity: .5;
            pointer-events: none;
        }
        &.rounded{
            border-radius: 100px;
        }
        &.variant-{
            &outlined{
                &.color-{
                    &primary{
                        color: v.$text-base-subdued;
                        &:hover{
                            background: transparent;
                            color: var(--branch-color);
                            border-color: v.$decorative-subdued;
                        }
                    }
                }
            }
            &contained{
                &.color-{
                    &primary{
                        &:hover{
                            border-color: var(--branch-color)
                        }
                    }
                }
            }
        }
    }
    &.sidebar-modal{
        background: transparent;
        color: v.$text-base;
        width: 100%;
        border-radius: 10px;
    }
}
.container{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: v.$text-highlight;
    font-family: v.$font-branch;
    font-weight: 500;
}

p, .p{
    color: v.$text-base-subdued;
    font-family: v.$font-branch;
}

span, .span{
    color: v.$text-subdued;
    font-family: v.$font-branch;
}

svg, .svg{
    font-size: 1em;
    width: 1em;
    height: 1em;
    color: currentColor;
    path, g{
        fill: currentColor;
        color: currentColor;
    }
}

.work-card{
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid v.$decorative-subdued;
    &:nth-last-child(1){
        border-color: transparent;
        padding-bottom: 0;
    }
    .card-logo{
        aspect-ratio: 1 / 1;
        background: #333;
        padding: .75rem;
        display: flex;
        font-size: 2rem;
        border-radius: 10px;
        svg {
            width: 1em;
            height: 1em;
            font-size: 1em;
        }
    }
    .card-body{
        display: flex;
        flex-flow: column;
        gap: .4rem;
    }
    
    span{
        color: v.$text-base-subdued;
        font-family: v.$font-branch;
    }
    h3{
        color: v.$text-base;
    }
}
.swiper-navigation{
    display: flex;
    padding: 2rem 0;
    gap: 1rem;
    justify-content: center;
}
.card-light-wrapper {
    position: relative;
    width: 100%;
}
.light-card{
    position: relative;
    width: 100%;
    border-radius: 16px;
    z-index: 0;
    border: 1px solid v.$decorative-subdued;
    &:hover{
        .light-layout{
            background: rgba(255, 255, 255, .04);
        }
    }
    .light {
        position: absolute;
        width: 200px;
        height: 200px;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.075) 0%, rgba(255, 255, 255, 0) 80%);
        pointer-events: none;
        mix-blend-mode: overlay;
        filter: blur(30px);
        opacity: 0;
        z-index: 1;
        transition: opacity 0.3s ease-out;
    }
    .light-layout {
        padding: 1rem;
        z-index: 1;
        transition: .2s ease all;
        position: relative;
        background: rgba(0, 0, 0, 0);
        backdrop-filter: blur(10px);
        color: white;
        border-radius: 10px;
    }
    
    .testimonial-header img {
        border-radius: 100px;
        width: 4rem;
    }

    .testimonial-body {
        margin-top: 1rem;
    }

    .testimonial-author {
        margin-top: 2rem;
        h4 {
            font-size: 1.25rem;
        }
        span {
            font-size: .875rem;
        }
    }

    .service-body{
        padding: 1rem 0;
        svg{
            font-size: 4.5rem;
            color: v.$text-subdued;
        }
        h2{
            color: v.$text-base;
        }
    }
}
.pricing-card{
    border: 1px solid v.$decorative-subdued;
    border-radius: 1.25rem;
    padding: 1rem;
    .pricing-header{
        border: 1px solid v.$decorative-subdued;
        border-radius: .75rem;
        padding: 1rem;
        h3{
            color: v.$text-base;
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        p{
            font-size: 1.25rem;
            color: v.$text-base-subdued;
            margin-bottom: 1rem;
        }
        .price{
            color: var(--branch-color);
            font-size: 2.5rem;
            font-weight: 500;
        }
        .timer{
            color: v.$text-base-subdued;
        }
    }
    .pricing-body{
        ul{
            padding: 1.5rem .5rem;
            list-style: none;
            li{
                display: flex;
                gap: .5rem;
            }
        }
    }
    .pricing-footer{
        padding: .5rem;
    }
}
.fade-out{
    .project-card{
        opacity: 0;
    }
}
.project-card{
    border-radius: 10px;
    border: 1px solid v.$decorative-subdued;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    position: relative;
    transition: .2s ease all;
    opacity: 1;
    .cover{
        width: 100%;
        aspect-ratio: 16 / 9;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            transform: scale(1.05);
            transition: .2s ease all;
        }
        .backdrop{
            position: absolute;
            inset: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            background: rgba($color: #000000, $alpha: .5);
            transition: .2s ease all;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .icon{
                color: v.$text-base-inverted;
                display: flex;
                padding: .75rem;
                font-size: 1.75rem;
                border-radius: 100px;
                background: var(--branch-color);
                svg{
                    font-size: 1em;
                }
            }
        }
    }
    .body{
        padding: 1rem;
    }
    &:hover{
        background: v.$input-highlight;
        .cover{
            img{
                transform: scale(1);
            }
            .backdrop{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
input.input,
.input{
    color: v.$text-base;
    font-family: v.$font-branch;
    background: v.$input-base;
    border: 1px solid v.$border-color;
    padding: .5rem;
    transition: .2s ease all;
    &.text-area{
        resize: none;
    }
    &.text-area:hover{
        background: v.$input-highlight;
    }
    input{
        font-family: v.$font-branch;
        color: v.$text-base;
    }
    input::placeholder{
        color: v.$text-base;
    }
    fieldset{
        border: 1px solid v.$border-color;
    }
    label{
        font-family: v.$font-branch;
        color: v.$text-base-subdued !important;
        &.Mui-error{
        }
    }
    &.success{
        fieldset{
        }
    }
    .Mui-focused{
        fieldset{
            border: 1px solid v.$border-color;
        }
    }
    
    p{
        font-family: v.$font-branch;
        color: v.$text-base;
    }

    .MuiInputBase-root{
        border-radius: 10px;
        transition: .15s ease all;
        &:hover{
            background: v.$input-highlight;
            fieldset{
                border-color: v.$decorative-subdued;
            }
        }
        &.Mui-error{
            fieldset{
            }
        }
    }
    &.date-picker{
        width: 100%;
        color: currentColor;
        div,
        button {
            color: currentColor;
            svg{
                color: currentColor;
            }
        }
    }
}

.input{
    font-family: v.$font-branch;
    padding: .5rem;
    border-radius: 3px;
    font-size: 1rem;
    border: 1px solid transparent;
    outline: 1px solid transparent;
    transition: .15s ease all;
    flex: 1;
    border-radius: 10px;
    &::placeholder{
        color: v.$text-base-subdued;
    }
    &.text-area{
        resize: none;
    }
    &.password,
    &.with-icon{
        padding-right: 2.5rem;
    }
    &.variant{
        &-1{
            border: 1px solid v.$border-color;
            &:hover{
                border-color: v.$decorative-subdued;
            }
            &:focus{
                background: v.$input-base;
                outline-color: v.$decorative-highlight;
            }
        }
        &-2{
        }
    }
}

.progress{
    &.circular{
        color: var(--branch-color);
    }
}

.border-{
    &bottom{
        border-bottom: 1px solid v.$decorative-subdued;
    }
}
.tabs-header{
    background: rgba($color: #fff, $alpha: .8);
}
.tabs{
    width: 100%;
    backdrop-filter: blur(10px);
    background: transparent;
    button{
        color: v.$text-base;
        font-family: v.$font-branch;
        &.Mui-selected{
            color: var(--branch-color);
        }
    }  
    .MuiTabs-indicator{
        background: var(--branch-color);
    }
}

.font-name{
    --font: "Poppins", sans-serif;
    font-family: var(--font);
    font-size: 1em;
    text-transform: lowercase;
    color: currentColor;
    &.blank{
        .dot{
            color: currentColor;
        }
    }
    .dot{
        font-family: "Edu AU VIC WA NT Hand", cursive;
        font-size: .725em;
        color: #EF3E30;
    }
}

.lazy{
    &.image{
        position: relative;
        overflow: hidden;
        background: v.$lazy-image-background;
        figure{
            position: absolute;
            inset: 0;
            background: transparent;
        }
        .image-body{
            opacity: 0;
            transition: .3s ease all;
            img {
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &.loaded{
            background: transparent;
            .image-body{
                opacity: 1;
            }
        }
        &.svg {
            .image-body{
                svg{
                    height: 100%;
                    fill: var(--branch-color);
                }
            }
        }
    }
}

@media (max-width: 728px) {
    .project-card{
        max-width: initial;
        min-width: 100%;
    }
}