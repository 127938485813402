@use '../../styles/variables' as v;
.curso{
    display: flex;
    flex-flow: column;
    .curso-background{
        width: 100%;
        height: 40vh;
        filter: brightness(.8);
    }
    .curso-header{
        --image-width: 7rem;
        max-width: 900px;
        padding-inline: 1rem;
        position: relative;
        margin: calc(var(--image-width) / 1.5 * -1) auto 0;
        .logo{
            width: var(--image-width);
            aspect-ratio: 1 / 1;
            background: v.$modal-background;
            padding: 1rem;
            border: 2px solid v.$text-base;
            border-radius: .5rem;
            overflow: hidden;
        }
    }
}