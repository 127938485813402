@use '../../styles/variables' as v;
.learn-swiper{
    display: flex;
    flex-flow: column;
    gap: 1rem;
    width: 100%;
    .swiper-slide{
        box-shadow: 
            0 .5rem 45px 0 rgba($color: v.$primary-color-asp, $alpha: 0), 
            0 .5rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .05), 
            0 .5rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .05), 
            0 .5rem 26px 0 rgba($color: v.$primary-color-asp, $alpha: .08),
            0 .5rem 14px 0 rgba($color: v.$primary-color-asp, $alpha: .09),
        ;
        border-radius: 1rem;
        aspect-ratio: 16 / 9;
        .slide{
            background: linear-gradient(-113.251deg, rgba(0, 0, 0, 0%) 0%, rgba(172, 153, 234, 10%) 100%), linear-gradient(113.251deg, rgba(249, 50, 44, 0%) 0%, rgba(249, 50, 44, 10%) 100%), linear-gradient(#FFFFFF, #FFFFFF);
            padding: 2.5rem;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
            h3{
                font-size: 3rem;
            }
            > div {
                display: flex;
                flex-flow: column;
                gap: 1rem;
                .button{
                    width: max-content;
                }
            }
        }
    }
    .swiper-thumbs{
        --padding: 2.5rem;
        width: calc(100% + var(--padding) * 2);
        padding: var(--padding);
        margin: calc(var(--padding) * -1);
        .thumb{
            padding: 2rem;
            aspect-ratio: 16 / 7;
            background: rgba($color: #FFFFFF, $alpha: .8);
            box-shadow: 
                0 1rem 45px 0 rgba($color: v.$primary-color-asp, $alpha: 0), 
                0 1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .01), 
                0 1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .01), 
                0 1rem 26px 0 rgba($color: v.$primary-color-asp, $alpha: .04),
                0 1rem 14px 0 rgba($color: v.$primary-color-asp, $alpha: .05),
            ;
            backdrop-filter: blur(10px);
            color: #555;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            transition: .2s ease all;
            svg{
                font-size: 4.25vmax;
            }
            &:hover{
                transform: translateY(-4px);
                color: #000;
                box-shadow: 
                    0 1rem 45px 0 rgba($color: v.$primary-color-asp, $alpha: 0), 
                    0 1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .08), 
                    0 1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .08), 
                    0 1rem 26px 0 rgba($color: v.$primary-color-asp, $alpha: .11),
                    0 1rem 14px 0 rgba($color: v.$primary-color-asp, $alpha: .12),
            ;
            }
        }
    }
}