@use '../../styles/variables' as v;
.backdrop{
    background: rgba($color: #000000, $alpha: .5);
    backdrop-filter: blur(8px);
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: .2s ease all;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
}
.modal{
    position: fixed;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    &.show{
        > .backdrop {
            visibility: visible;
            opacity: 1;
            background: rgba($color: #000000, $alpha: .5);
        }
        > .modal_layout{
            scale: 1;
            visibility: visible;
            opacity: 1;
        }
    }
    > .backdrop {
        opacity: 0;
        visibility: hidden;
    }
    &.padding-false{
        .modal_layout .content{
            padding: 0;
        }
    }
    &.variant-fs{
        .modal_layout{
            height: 100%;
            max-width: initial;
            border: none;
            transition: .4s ease all;
            opacity: 0;
            visibility: hidden;
            border: none;
            scale: 1;
            margin: 0;
            border-radius: 0;
            .content{
                height: 100%;
            }
        }
        &.show{
            .modal_layout{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.variant-fh{
        .modal_layout{
            height: 100%;
            max-height: 95%;
            overflow: hidden;
            .content{
                height: 100%;
            }
        }
    }
    .modal_layout{
        position: relative;
        display: flex;
        flex-flow: column;
        border-radius: 10px;
        scale: .9;
        transition: .2s ease all;
        visibility: hidden;
        opacity: 0;
        max-width: 600px;
        margin: .5rem;
        width: 100%;
        overflow: hidden;
        border: 1px solid v.$decorative-subdued;
        background: v.$modal-background;
        .title-layout{
            display: flex;
            padding: .5rem;
            width: 100%;
            position: sticky;
            top: 0;
            .title{
                flex: 1;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1;
                padding: .5rem;
            }
            .IconButton{
                color: inherit;
            }
        }
        .back-button{
            position: sticky;
            top: -.75rem;
            z-index: 2;
            margin: -.75rem -1rem 1.75rem;
            background: #121221;
        }
        .content{
            width: 100%;
            overflow: auto;
            height: max-content;
            max-height: 100%;
            padding: .5rem 1rem;
        }
        .sidebar-modal{
            .navigation{
                list-style: none;
                display: flex;
                flex-flow: column;
                gap: .5rem;
                padding: 1rem .5rem;
            }
        }
        .modal-buttons{
            display: flex;
            gap: 1rem;
            margin: 1rem 0 .5rem;
        }
    }
}
@media (max-width: 799px){
    .modal{
        .modal_layout{
            scale: 1;
            width: 95%;
            position: absolute;
            bottom: -100%;
        }
        &.show{
            .modal_layout{
                bottom: 0;
                max-height: 90%;
            }
        }
        
        
        &.variant-fh,
        &.variant-fs{
            .modal_layout{
                max-width: initial;
                bottom: initial;
                max-height: 100%;
                border-radius: 0;
            }
        }
    }
}