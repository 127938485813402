@use '../../styles/variables' as v;
.footer{
    padding: 1.5rem 1.5rem 1rem;
    position: relative;
    z-index: 98;
    min-height: 10rem;
    background: #FFF; 
    outline: 5px solid #FFF;
    background: 
        linear-gradient(90deg, rgba(249, 50, 44, 15%) 0%, rgba(172, 153, 234, 30%) 100%), 
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -1rem 45px 0 rgba($color: v.$primary-color-asp, $alpha: 0), 
                0 -1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .1), 
                0 -1rem 41px 0 rgba($color: v.$primary-color-asp, $alpha: .05), 
                0 -1rem 26px 0 rgba($color: v.$primary-color-asp, $alpha: .08),
                0 -1rem 14px 0 rgba($color: v.$primary-color-asp, $alpha: .09),
    ;
    footer{
        display: flex;
        flex-flow: column;
        align-items: center;
        height: auto;
        flex: 1;
    }
    .logo{
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        font-family: v.$font-branch;
    }
    p{
        text-align: center;
    }
}